<template>
  <b-row
    class="mb-3"
  >
    <b-col
      md="4"
    >
      <b-row>
        <b-col md="12">
          <b-form-group
            :label="$t('invoice_number')"
          >
            <ValidationProvider
              v-slot="{ errors }"
              :vid="`${typeOfOrder === 'bulk order' ? `orders.${index}.invoice_number` : 'invoice_number'}`"
              name="Invoice Number"
            >
              <b-form-input
                v-model="order.invoice_number"
                type="text"
                :placeholder="$t('enter_invoice_number')"
              />
              <span class="text-danger">{{ errors[0] }}</span>
            </validationprovider>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            :label="$t('po_number')"
          >
            <ValidationProvider
              v-slot="{ errors }"
              :vid="`${typeOfOrder === 'bulk order' ? `orders.${index}.po_number` : 'po_number'}`"
              name="PO Number"
            >
              <b-form-input
                id="po-number"
                v-model="order.po_number"
                type="text"
                :placeholder="$t('enter_po_number')"
              />
              <span class="text-danger">{{ errors[0] }}</span>
            </validationprovider>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            :label="$t('po_expiry_date')"
          >
            <ValidationProvider
              v-slot="{ errors }"
              :vid="`${typeOfOrder === 'bulk order' ? `orders.${index}.po_expiry_date` : 'po_expiry_date'}`"
              name="PO Expiry Date"
            >
              <flat-pickr
                id="po-expiry-date"
                v-model="order.po_expiry_date"
                :placeholder="$t('src.views.pages.inside.createdelivery.yyyymmdd')"
                class="form-control"
                :config="{minDate: defaultPoExpiryDateTime}"
              />
              <span class="text-danger">{{ errors[0] }}</span>
            </validationprovider>
          </b-form-group>
        </b-col>
      </b-row>
    </b-col>
    <b-col
      md="4"
      class="px-lg-5"
    >
      <b-row>
        <b-col md="12">
          <b-form-group
            :label="$t('select_product_category')"
          >
            <ValidationProvider
              v-slot="{ errors }"
              :vid="`${typeOfOrder === 'bulk order' ? `orders.${index}.category_id` : 'category_id'}`"
              name="Category"
            >
              <b-form-radio
                v-for="option in categories"
                :id="`categories-${index}-${option.value}`"
                :key="option.value"
                v-model="order.category_id"
                :value="option.value"
                :name="`categories-${index}`"
              >
                {{ option.text }}
              </b-form-radio>
              <span class="text-danger">{{ errors[0] }}</span>
            </validationprovider>
          </b-form-group>

        </b-col>
        <b-col md="12">
          <b-form-group
            :label="$t('select_packaging')"
          >
            <ValidationProvider
              v-slot="{ errors }"
              :vid="`${typeOfOrder === 'bulk order' ? `orders.${index}.packaging` : 'packaging'}`"
              name="Packaging"
            >
              <b-form-radio
                v-for="option in packaging"
                :id="`package-${index}-${option.value}`"
                :key="option.value"
                v-model="order.selectedPackaging"
                :value="option.text"
                :name="`package-${index}`"
              >
                {{ option.text }}
              </b-form-radio>
              <span
                class="text-danger"
              >{{ errors[0] }}</span>
            </ValidationProvider>
            <div v-if="order.selectedPackaging === 'Others'">
              <ValidationProvider
                v-slot="{ errors }"
                :vid="`${typeOfOrder === 'bulk order' ? `orders.${index}.packaging` : 'packaging'}`"
                name="Packaging"
              >
                <b-form-input
                  :id="`otherpackaging-${index}`"
                  v-model="order.custom_package"
                  :placeholder="$t('others')"
                  class="mt-1"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            :label="$t('select_remarks')"
          >
            <ValidationProvider
              v-slot="{ errors }"
              :vid="`${typeOfOrder === 'bulk order' ? `orders.${index}.remarks` : 'remarks'}`"
              name="Remarks"
            >
              <b-form-radio
                v-for="option in remarks"
                :id="`remarks-${index}-${option.value}`"
                :key="option.value"
                v-model="order.selectedRemarks"
                :value="option.value"
                :name="`remarks-${index}`"
              >
                {{ option.text }}
              </b-form-radio>
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
            <div
              v-if="order.selectedRemarks === 'Others'"
              md="6"
              class="pt-0"
            >
              <ValidationProvider
                v-slot="{ errors }"
                :vid="`${typeOfOrder === 'bulk order' ? `orders.${index}.remarks` : 'remarks'}`"
                name="Remarks"
              >
                <b-form-input
                  :id="`otherRemarks-${index}`"
                  v-model="order.custom_remarks"
                  class="mt-1"
                  :placeholder="$t('src.views.pages.inside.createdelivery.other')"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
    </b-col>
    <b-col md="4">
      <b-form-group
        :label="$t('quantity')"
      >
        <ValidationProvider
          v-slot="{ errors }"
          :vid="`${typeOfOrder === 'bulk order' ? `orders.${index}.qty` : 'qty'}`"
          name="Quantity"
        >
          <b-form-input
            id="product-quantity"
            v-model="order.qty"
            type="number"
            :placeholder="$t('enter_quantity')"
            @input="typeOfOrder === 'bulk order' ? $emit('calculatePrice') : () =>{}"
          />
          <span class="text-danger">{{ errors[0] }}</span>
        </ValidationProvider>
      </b-form-group>
      <b-form-group>
        <ValidationProvider
          v-slot="{ errors }"
          :vid="`${typeOfOrder === 'bulk order' ? `orders.${index}.size` : 'size'}`"
          name="Size"
        >
          <slot name="label">
            {{ $t('src.views.pages.inside.createdelivery.size') }}
            <feather-icon
              :id="`size-tooltip-${index}`"
              style="margin-bottom: 5px"
              size="20"
              icon="InfoIcon"
              :variant="'primary'"
            />
            <b-tooltip
              :target="`size-tooltip-${index}`"
              triggers="hover"
              no-fade
            >
              {{ $t('double_charge_limit') }}: <br>50 x 38 x 35 cm<br>1.6 x 1.2 x 1.1 ft<br>19.6 x 14.9 x 13.7 inch <br><br>
              {{ $t('max_limit') }}: <br>100 x 76 x 70 cm<br>3.2 x 2.4 x 2.2 ft<br>39.2 x 29.8 x 27.4 inch
            </b-tooltip>
          </slot>
          <b-input-group>
            <b-form-input
              :id="`length-${index}`"
              v-model="order.length"
              type="number"
              class="border-right-0 text-center px-0"
              :placeholder="`0 ${order.unit}`"
              @input="setSizeValue"
            />
            <b-input-group-text class="border-right-0 border-left-0 rounded-0 p-0">
              <feather-icon icon="XIcon" />
            </b-input-group-text>
            <b-form-input
              :id="`width-${index}`"
              v-model="order.width"
              type="number"
              class="border-right-0 border-left-0 rounded-0 text-center px-0"
              :placeholder="`0 ${order.unit}`"
              @input="setSizeValue"
            />
            <b-input-group-text class="border-right-0 border-left-0 rounded-0  p-0">
              <feather-icon icon="XIcon" />
            </b-input-group-text>
            <b-form-input
              :id="`height-${index}`"
              v-model="order.height"
              type="number"
              class="border-left-0 text-center px-0"
              :placeholder="`0 ${order.unit}`"
              @input="setSizeValue"
            />
            <select
              id="size-unit"
              v-model="order.unit"
              class="custom-select append-left"
            >
              <option
                value="unit"
                disabled
                selected
                hidden
              >
                {{ $t('src.views.pages.inside.createdelivery.unit') }}
              </option>
              <option value="cm">
                cm
              </option>
              <option value="in">
                in
              </option>
              <option value="ft">
                ft
              </option>
            </select>
          </b-input-group>
          <span class="text-danger">{{ errors[0] }}</span>
        </ValidationProvider>
      </b-form-group>
      <b-form-group
        label="SKU"
      >
        <ValidationProvider
          v-slot="{ errors }"
          :vid="`${typeOfOrder === 'bulk order' ? `orders.${index}.sku` : 'sku'}`"
          name="SKU"
        >
          <b-form-input
            :id="`sku-${index}`"
            v-model="order.sku"
            type="text"
            :placeholder="$t('enter_sku')"
          />
          <span class="text-danger">{{ errors[0] }}</span>
        </ValidationProvider>
      </b-form-group>
      <b-form-group
        :label="$t('temperature')"
      >
        <ValidationProvider
          v-slot="{ errors }"
          :vid="`${typeOfOrder === 'bulk order' ? `orders.${index}.temperature` : 'temperature'}`"
          name="Temperature"
        >
          <v-select
            v-model="order.temperature"
            :placeholder="$t('select_temperature')"
            :options="['Ambience', 'Chill', 'Frozen']"
            :clearable="false"
          />
          <span class="text-danger">{{ errors[0] }}</span>
        </ValidationProvider>
      </b-form-group>
      <b-form-group>
        <ValidationProvider
          v-slot="{ errors }"
          :vid="`${typeOfOrder === 'bulk order' ? `orders.${index}.weight` : 'weight'}`"
          name="Weight"
        >
          <slot name="label">
            {{ $t('src.views.pages.inside.createdelivery.weight') }}
            <feather-icon
              :id="`weight-tooltip-${index}`"
              size="20"
              icon="InfoIcon"
              style="margin-bottom: 5px"
            />
            <b-tooltip
              :target="`weight-tooltip-${index}`"
              triggers="hover"
              no-fade
            >
              Double charge limit: 15kg <br><br>
              Max limit: 40kg
            </b-tooltip>
          </slot>
          <b-input-group append="kg">
            <b-form-input
              :id="`weight-${index}`"
              v-model="order.weight"
              type="number"
              min="0.5"
              :placeholder="$t('src.views.pages.inside.createdelivery.weight')"
              @input="handleWeightInput"
            />
          </b-input-group>
          <span class="text-danger">{{ errors[0] }}</span>
        </ValidationProvider>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'

export default {
  name: 'ProductDetails',
  components: {
    vSelect,
    flatPickr,
    ValidationProvider,
  },
  props: {
    index: {
      type: Number,
      default: 0,
    },
    order: {
      type: Object,
      default: () => {},
    },
    categories: {
      type: Array,
      default: () => [],
    },
    packaging: {
      type: Array,
      default: () => [],
    },
    remarks: {
      type: Array,
      default: () => [],
    },
    typeOfOrder: {
      type: String,
      default: 'single order',
    },
  },
  data() {
    return {
      resetSize: {
        size: null, length: null, width: null, height: null,
      },
    }
  },
  computed: {
    defaultPoExpiryDateTime() {
      const today = new Date()
      today.setDate(today.getDate() + 1)
      return today
    },
  },
  methods: {
    setSizeValue() {
      this.order.size = `${this.order.length} x ${this.order.width} x ${this.order.height}`
      if (this.order.length && this.order.width && this.order.height) {
        if (this.$validate.isSizeOnMaxLimitation(this.order.size, this.order.unit)) {
          this.$swal({
            title: 'Contact Customer Service',
            text: 'Oops! Your desire order has reached to our final limitation. In order to proceed for ordering, kindly proceed to contact with our Customer Service representative at this hotline 019-8196692. Thank you!',
            icon: 'error',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#ea5455',
          })
          return
        }
        if (this.typeOfOrder !== 'single order') {
          this.$emit('calculatePrice')
          if (this.$validate.isSizeOnDoubleCharge(this.order.size, this.order.unit)) {
            this.$swal({
              title: 'Double Charged',
              text: 'You will be double charged due to size/weight limit exceeded! Are you sure want to continue?',
              icon: 'warning',
              allowOutsideClick: false,
              showCancelButton: true,
              confirmButtonText: 'Yes',
              customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-outline-danger ml-1',
              },
              buttonsStyling: false,
            }).then(result => {
              if (result.isConfirmed) {
                this.$emit('calculatePrice')
              }
              if (result.isDismissed) {
                this.$store.dispatch('bulkDelivery/updateOrderForm', { data: this.resetSize, index: this.index })
              }
            })
          }
        }
      }
    },
    handleWeightInput() {
      if (this.$validate.isWeightOnMaxLimitation(this.order.weight)) {
        this.$swal({
          title: 'Contact Customer Service',
          text: 'Oops! Your desire order has reached to our final limitation. In order to proceed for ordering, kindly proceed to contact with our Customer Service representative at this hotline 017-2881735. Thank you!',
          icon: 'error',
          confirmButtonText: 'Ok',
          confirmButtonColor: '#ea5455',
        })
        return
      }

      if (this.typeOfOrder !== 'single order') {
        this.$emit('calculatePrice')
        if (this.$validate.isWeightOnDoubleCharge(this.order.weight)) {
          this.$swal({
            title: 'Double Charged',
            text: 'You will be double charged due to size/weight limit exceeded! Are you sure want to continue?',
            icon: 'warning',
            allowOutsideClick: false,
            showCancelButton: true,
            confirmButtonText: 'Yes',
            customClass: {
              confirmButton: 'btn btn-success',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.isConfirmed) {
              this.$emit('calculatePrice')
            }
            if (result.isDismissed) {
              this.$store.dispatch('bulkDelivery/updateOrderForm', { data: { weight: null }, index: this.index })
            }
          })
        }
      }
    },
  },
}
</script>
